import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const JokerAudioComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const audioRef = useRef();

  useEffect(() => {
    if (!audioRef.current) {
      audioRef.current = new Audio('/media/joker.mp3');
    }

    audioRef.current.play().catch((error) => {
      console.error('Error playing audio:', error);
    });

    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
    };
  }, [location.state, navigate]);

  // Rotate the logo
  const [rotation, setRotation] = useState(0);

  // Rotate the logo continuously
  useEffect(() => {
    const interval = setInterval(() => {
      setRotation((prevRotation) => prevRotation + 1); // Increment the rotation angle
    }, 10); // Adjust the interval to control the rotation speed

    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(interval);
    };
  }, []);

  const logoStyle = {
    width: '200px',
    height: '200px',
    transform: `rotate(${rotation}deg)`, // Apply the rotation angle
    margin: '0 auto', // Center the logo horizontally
    display: 'block',
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '60vh', flexDirection: 'column' }}>
      <img
        className="hero-smiley rotating"
        src={process.env.PUBLIC_URL + '/images/hero/HeroPageSmileyLogoBlack.png'}
        alt=""
        style={logoStyle}
      />
      <div style={{marginTop:'15px'}}>
        <h2 style={{marginTop:'30px'}}>
          WHAT? DO YOU THINK THAT'S FUNNY? <br></br>
          OBVIOUSLY YOU DIDN'T READ PROPERLY. <br></br>
          YOU NEED TO PAY MORE ATTENTION.
        </h2>
      </div>
    </div>
  );
};

export default JokerAudioComponent;
