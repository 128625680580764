function Roadmap() {
    return (
        <div className="roadmap" id="roadmap">
        <div className="roadmap-header-splatter">
        <img src={process.env.PUBLIC_URL + "/images/roadmap/RoadMapPaintSplatter.png"} alt="" /></div>
        <div className="roadmap-header"><div className="green"><br /> ROAD&nbsp;</div><br /> MAP</div>
        
        <div className="roadmap-bg-container">

        <img className="RoadMapImageBackground" src={process.env.PUBLIC_URL + "/images/roadmap/RoadMapImageBackground3.png"} alt="" />
        <img className="roadmap-vertical-line" src={process.env.PUBLIC_URL + "/images/roadmap/VerticalLineRoadMapPage.png"} alt="" /><img className="roadmap-vertical-line-4150" src={process.env.PUBLIC_URL + "/images/roadmap/VerticalLineRoadMapPage4150px.png"} alt="" />

        <div className="roadmap-item-header"> <div className="roadmap-numbers-left"> 1 </div> <img className="roadmap-horizontal-line-left" src={process.env.PUBLIC_URL + "/images/roadmap/LeftLine10RulesPage.png"} alt="" /> </div> <div className="roadmap-section-left">Grow a community so the project can grow organically. This is not a hype based project, this is utility based project. A very unique approach to minting, to fit the needs of everyone interested in Nfts. Whether it be to flip or hold long term.</div>
        <div className="roadmap-item-header"><img className="roadmap-horizontal-line-right" src={process.env.PUBLIC_URL + "/images/roadmap/RightLine10RulesPage.png"} alt="" /> <div className="roadmap-numbers-right"> 2 </div></div> <div className="roadmap-section-right">Create a wallet to be used in the app. This will bring huge value to the platform in many ways. And will be a key part of how the app functions.</div>
        <div className="roadmap-item-header"> <div className="roadmap-numbers-left"> 3 </div> <img className="roadmap-horizontal-line-left" src={process.env.PUBLIC_URL + "/images/roadmap/LeftLine10RulesPage.png"} alt="" /> </div> <div className="roadmap-section-left">Build the authentication model to verify NFTs holders, granting them access to the platform while keeping bots out.</div>
        <div className="roadmap-item-header"><img className="roadmap-horizontal-line-right" src={process.env.PUBLIC_URL + "/images/roadmap/RightLine10RulesPage.png"} alt="" /> <div className="roadmap-numbers-right"> 4 </div></div> <div className="roadmap-section-right">Desing and develop products to be purchased on the platform in which NFTs holders will be able to access to. Being an NFTs holder will have many benefice and will offer NFT holders passive income opportunity.</div>
        <div className="roadmap-item-header"> <div className="roadmap-numbers-left"> 5 </div> <img className="roadmap-horizontal-line-left" src={process.env.PUBLIC_URL + "/images/roadmap/LeftLine10RulesPage.png"} alt="" /> </div> <div className="roadmap-section-left">Beta testing for the app. Everyone holding an nft will get to be a part of the beta app so that we can work out all of the bugs before launching to the rest of the world. Focus on bring marketing and mass adoption of the app that is built. This will be the biggest test of our community. The more the merrier its a vibe.</div>
        <div className="roadmap-item-header"><img className="roadmap-horizontal-line-right" src={process.env.PUBLIC_URL + "/images/roadmap/RightLine10RulesPage.png"} alt="" /> <div className="roadmap-numbers-right"> 6 </div></div> <div className="roadmap-section-right">purchase servers in order to secure true independence</div>
        <div className="roadmap-item-header"> <div className="roadmap-numbers-left"> 7 </div> <img className="roadmap-horizontal-line-left" src={process.env.PUBLIC_URL + "/images/roadmap/LeftLine10RulesPage.png"} alt="" /> </div> <div className="roadmap-section-left">Create a Token aiding the platform to be decentralized</div>
        {/* <div className="roadmap-item-header"><img className="roadmap-horizontal-line-right" src={process.env.PUBLIC_URL + "/images/roadmap/RightLine10RulesPage.png"} alt="" /> <div className="roadmap-numbers-right"> 8 </div></div> <div className="roadmap-section-right">Minting will be what makes this project so unique. Minting will be released in batches. There will be 10 batches of 1k. The first 1k are for the OG belivers. They will get special perks. These are open to everyone to get the party started.</div>
        <div className="roadmap-item-header"> <div className="roadmap-numbers-left"> 9 </div> <img className="roadmap-horizontal-line-left" src={process.env.PUBLIC_URL + "/images/roadmap/LeftLine10RulesPage.png"} alt="" /> </div> <div className="roadmap-section-left">The mint price will be progressive. This will allow you to take profits along the way if you choose to do so. It will also help keep people in the project because they want to be here. Not just because they want a quick flip. Nothing wrong with taking profits at all. Minting price will start at .1 eth and progress to 1 eth.</div>
        <div className="roadmap-item-header"><img className="roadmap-horizontal-line-right" src={process.env.PUBLIC_URL + "/images/roadmap/RightLine10RulesPage.png"} alt="" /> <div className="roadmap-numbers-right"> 10 </div></div> <div className="roadmap-section-right">There will be no need for WL. Everyone that is passionate about the project and active in the community will have a fair chance. There also is no limit to how many you can mint per wallet. Similar to stocks I feel if you belive in something you should not be given a cap on the quantity of something that you choose to invest in.</div>
         */}
        
        <div className="roadmap-container"> 
        </div> 
        </div> 
        </div>
    )
}    
 
export default Roadmap;