import React from 'react';
import styled from 'styled-components';

const Header = () => {
    return (
        <ProfileHeader>
        <HeaderLogo src={process.env.PUBLIC_URL + "/images/app/headerIconWhite.png"} />
        <ProjectDefiLogo src={process.env.PUBLIC_URL + "/images/app/projectDefiWhite.png"} />
        <BagIcon src={process.env.PUBLIC_URL + "/images/app/bagIcon.png"}/>
        <HamburgerIcon src={process.env.PUBLIC_URL + "/images/app/hamburgerIcon.png"} />
        </ProfileHeader>
    );
};

export default Header;

const ProfileHeader = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 100vw; /* Add max-width to prevent overflow */
    height: 7vh;
    background-color: black;
    align-items: center;
`;

const HeaderLogo = styled.img`
    height: 70%;
    margin-left: 1vw;
    margin-right: 2vw;
        max-width: 10vw;

`;

const ProjectDefiLogo = styled.img`
    height: 80%;
    max-width: 40vw;
    margin-right: 2vw; 
`;

const BagIcon = styled.img`
    margin-top: 2vh;
    height: 95%;
    max-width: 10vw;
    margin-bottom: 2vh;
    margin-right: 2vw;
`;

const HamburgerIcon = styled.img`
    margin-top: 1vh;
    height: 50%;
    max-width: 10vw;
    margin-bottom: 2vh;
    margin-right: 2vw;
`;

