import React from 'react';
import styled from 'styled-components';

const ProfileSection = ({ profileName }) => { // Accept profileName as a prop
    return (
        <ProfileHeader>
            {/* Display the dynamic profileName instead of static 'Dalton' */}
            <h1 style={{ fontFamily: 'philosopher', fontWeight: 'bold', height:'3vh' }}>
                {profileName}
            </h1>
            <button className='button-white' style={{ justifyContent: 'center', width:'70%', marginLeft:'15%', marginTop: '1vh', height:'6vh'  }}>
                Kanect
            </button>
            <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '1vh', height:'6vh' }}>
                <HalfButton className='button-white'>Share</HalfButton>
                <HalfButton className='button-white'>DM</HalfButton>
            </div>
            <div style={{ display: 'flex', marginTop: '1vh', height:'6vh' }}>
                <button className='button-black-alternate' style={{ justifyContent: 'center', width:'70%', marginLeft:'15%' }}>
                    Kanections
                </button>
            </div>
        </ProfileHeader>
    );
}

export default ProfileSection;

const ProfileHeader = styled.div`
  display: flex;
  text-align: center;
  flex-direction: column;
  width: 50%;
  color: white;
  height: 100%;
  max-height: 27vh;
`;

const HalfButton = styled.div`
  width: 40%;
  justify-content: center;
`;
