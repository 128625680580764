import React from 'react';
import styled from 'styled-components';
import ContentItem from './ContentItem';

const ContentContainer = () => {
    return (
        <StyledContentContainer>
            <ContentItem image={process.env.PUBLIC_URL + "/images/Demo/car1.jpg"} style={{ marginLeft: '-17.5%' }} />
            <ContentItem image={process.env.PUBLIC_URL + "/images/Demo/tattoo1.jpg"}/>
            <ContentItem image={process.env.PUBLIC_URL + "/images/Demo/btc1.jpg"}/>
            <ContentItem image={process.env.PUBLIC_URL + "/images/Demo/diy1.jpg"} style={{ marginRight: '-17.5%' }} />
        </StyledContentContainer>
    );
};

export default ContentContainer;

const StyledContentContainer = styled.div`
    width: 100%;
    height: 15vh;
    background-color: black;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1vh;
`;