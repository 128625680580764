import Main from "./main";
import Auth from "./components/Auth";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Tos from "./components/Tos";
import WebPlatform from "./components/WebPlatform";
import AccountDetails from "./components/AccountDetails";
import ProfilePage from './components/Profile/ProfilePage';
import ModifyProfile from './components/ModifyProfile'

function App() {

  return (
    
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Main/>} />
        <Route path="/auth" element={<Auth/>} />
        <Route path="/tos" element={<Tos/>} />
        <Route path="/app" element={<WebPlatform/>} />
        <Route path="/account-details" element={<AccountDetails/>} />
        <Route path="/p/:profileName" element={<ProfilePage />} />
        <Route path="/profile/edit" element={<ModifyProfile />} />
        
      </Routes>
      <ToastContainer autoClose={4000} hideProgressBar theme="colored" />
      </BrowserRouter>
    
  );
}

export default App;
