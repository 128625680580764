import React from 'react';
import styled from 'styled-components';

const ProfilePicture = () => {
    return (
        <Picture src={process.env.PUBLIC_URL + "/images/Demo/dalton.jpg"}/>
    );
}

const Picture = styled.img`
width: 50%;
height: '22vh';
max-height: '22vh';
border-radius: 33px;
border: 1px solid gray;
margin-left: 1vh;
margin-bottom: 1vh;
`;

export default ProfilePicture;
