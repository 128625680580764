function Rules() {
    return (
        <div className="rules">
            <div className="rules-header"><img className="rules-splash-small"src="/images/rules/PaintSplatterSmallFormat.png" alt="" /><img className="rules-splash" src={process.env.PUBLIC_URL + "/images/rules/10RulesPaintSplatter.png"} alt="" />
            <div className="rules-pink-ten"> <div className="green"> 10&nbsp;</div> RULES </div> OF PROJECT DEFI</div>
            {/*<img src={process.env.PUBLIC_URL + "/images/rules/%BACKGROUNDHERE%"} alt="" />*/}
            <div className="rules-content">
            <div className="rules-item"><img className="rules-numbers" src={process.env.PUBLIC_URL + "/images/rules/Numbers-01.png"} alt="" />
            <p>The <span style={{ color: 'green' }}>FIRST</span> rule of Project Defi, you always talk about Project Defi.</p></div>
            <div className="rules-item"><img className="rules-numbers" src={process.env.PUBLIC_URL + "/images/rules/Numbers-02.png"} alt="" />
            <p>The <span style={{ color: 'green' }}>SECOND</span> rule of Project Defi, YOU ALWAYS TALK ABOUT PROJECT DEFI.</p></div>
            <div className="rules-item"><img className="rules-numbers" src={process.env.PUBLIC_URL + "/images/rules/Numbers-03.png"} alt="" />
            <p>Never ever KYC [ know your customer ] for your Crypto. One of the most powerful qualities of Crypto, is the ability to stay pseudo-anonymous or ideally completely anonymous.</p></div>
            <div className="rules-item"><img className="rules-numbers" src={process.env.PUBLIC_URL + "/images/rules/Numbers-04.png"} alt="" />
            <p>Keep all your Crypto in your own physical wallet. Crypto exchanges are exactly like evil banks. If your Crypto is on an exchange it isn;t your Crypto, it is property of the exchange.</p></div>
            <div className="rules-item"><img className="rules-numbers" src={process.env.PUBLIC_URL + "/images/rules/Numbers-05.png"} alt="" />
            <p>Memorize and safeguard your seed phrase. It's only 24 words at max.</p></div>
            <div className="rules-item"><img className="rules-numbers" src={process.env.PUBLIC_URL + "/images/rules/Numbers-06.png"} alt="" />
            <p>There is a difference between FUD [ fear, uncertainty and doubt ] and true concerns.</p></div>
            <div className="rules-item"><img className="rules-numbers" src={process.env.PUBLIC_URL + "/images/rules/Numbers-07.png"} alt="" />
            <p>When someone says GM, you must respond back with a GM.</p></div>
            <div className="rules-item"><img className="rules-numbers" src={process.env.PUBLIC_URL + "/images/rules/Numbers-08.png"} alt="" />
            <p>The final rule. Follow for a follow, a strong tigh community can accomplish anything!!!</p></div>
            </div>
        </div>
    )
}    
 
export default Rules;