import React from 'react';
import styled from 'styled-components';

const Interest = (props) => {
    return (
        <InterestCard style={props.style}>
            <InterestContent>
                <InterestImage src={props.image} alt={props.title} />
            </InterestContent>
            <InterestTitle>
                <h3 style={{ fontFamily: 'philosopher' }} className="title">
                    {props.title}
                </h3>
            </InterestTitle>
        </InterestCard>
    );
};

const InterestCard = styled.div`
    flex: 1;
    position: relative;
    height: 100%;
`;

const InterestContent = styled.div`
    height: 80%;
    background-color: black;
    margin: 2px 4px 4px 4px;
    border-radius: 33px;
`;

const InterestTitle = styled.div`
    border-top: 1px solid black;
    width: 100%;
    text-align: center;
    color: white;
    position: relative;
    height: 20%;
`;

const InterestImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 33px;
    border: 1px solid gray
`;

export default Interest;
