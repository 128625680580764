import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import Header from './ProfileComponents/Header';
import Footer from './ProfileComponents/Footer';
import InterestContainer from './ProfileComponents/InterestContainer';
import ProfilePicture from './ProfileComponents/ProfilePicture';
import ProfileSection from './ProfileComponents/ProfileSection';
import MusicBox from './ProfileComponents/MusicBox';
import ContentContainer from './ProfileComponents/ContentContainer';
import ContentContainer2 from './ProfileComponents/ContentContainer2';
import { useParams } from 'react-router-dom';

const ProfilePage = () => {
  const { profileName } = useParams(); // Get profileName from URL
  const [profile, setProfile] = useState(null); // Initially null
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state

  // Fetch the profile data from the backend
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.get(`/api/profile/${profileName}`);
        setProfile(response.data); // Set the fetched data to profile state
        console.log(response)
        setLoading(false); // Set loading to false once data is fetched
      } catch (err) {
        setError('Error fetching profile data');
        setLoading(false);
      }
    };

    fetchProfile();
  }, [profileName]);

  // Handle loading and error states
  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  // If profile is still null, prevent rendering components that depend on profile data
  if (!profile) return <div>No profile data found</div>;

  return (
    <>
      <Container>
        <Header />
        {profile.profileInterest && (
          <InterestContainer interests={profile.profileInterest} />
        )}
        <div style={{ display: 'flex', maxHeight: '27vh' }}>
          {profile.profileName && (
            <>
              <ProfilePicture profileName={profile.profileName} /> {/* Pass profileName */}
              <ProfileSection profileName={profile.profileName} /> {/* Pass profileName */}
            </>
          )}
        </div>
        <MusicBox />
        <div style={{ height: '22vh', maxHeight: '22vh', overflow: 'hidden' }}>
          <ContentContainer />
          <ContentContainer2 />
        </div>
        <Footer />
      </Container>
      <InvitationMessage>
        <p>
          Experience the full content on your tablet or phone for the best
          viewing experience!
        </p>
      </InvitationMessage>
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden; /* Prevents the whole page from scrolling */
  @media (min-width: 768px) {
    display: none;
  }
`;

const InvitationMessage = styled.div`
  display: none;
  @media (min-width: 768px) {
    display: flex;
    text-align: center;
    color: rgb(241, 4, 176);
    margin-top: 5vh;
    margin-left: 3vw;
  }
`;

export default ProfilePage;
