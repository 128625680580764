//This component was copied into ProfilePage.js to continue development in September 2024.

import React from 'react';
import styled from 'styled-components';
import Header from './Profile/ProfileComponents/Header';
import Footer from './Profile/ProfileComponents/Footer';
import InterestContainer from './Profile/ProfileComponents/InterestContainer';
import ProfilePicture from './Profile/ProfileComponents/ProfilePicture';
import ProfileSection from './Profile/ProfileComponents/ProfileSection';
import MusicBox from './Profile/ProfileComponents/MusicBox';
import ContentContainer from './Profile/ProfileComponents/ContentContainer';
import ContentContainer2 from './Profile/ProfileComponents/ContentContainer2';

const AppPage = () => {
    return (
        <>
        <Container>
            <Header />
            <InterestContainer />
            <div style={{ display: 'flex', maxHeight: '27vh' }}>
                <ProfilePicture />
                <ProfileSection />
            </div>
            <MusicBox />
            <div style={{height:'22vh', maxHeight: '22vh', overflow:'hidden'}}>
                <ContentContainer />
                <ContentContainer2 />
                {/* Need to refactor the ContentContainer */}
            </div>
            <Footer />
        </Container>
        <InvitationMessage>
                <p>Experience the full content on your tablet or phone for the best viewing experience!</p>
        </InvitationMessage>
        </>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: hidden; /* Prevents the whole page from scrolling */
    @media (min-width: 768px) {
        display: none;
    }
`;

const InvitationMessage = styled.div`
    display: none;
    @media (min-width: 768px) {
        display: flex;
        text-align: center;
        color: rgb(241, 4, 176);
        margin-top: 5vh;
        margin-left: 3vw;
    }
`;

export default AppPage;
