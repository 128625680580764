import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';

const ModifyProfile = () => {
    const [profile, setProfile] = useState({
        profileName: '',
        profileInterest: ''
    });
    const [newProfile, setNewProfile] = useState(profile);
    const [image, setImage] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        // Fetch the user profile data from the backend
        const fetchProfile = async () => {
            try {
                const response = await axios.get('/api/getProfile');
                if (response.data.success) {
                    setProfile(response.data.profile);
                    setNewProfile(response.data.profile); // Populate the form
                    console.log("response: ", response)
                } else {
                    setErrorMessage('Failed to fetch profile');
                }
            } catch (error) {
                console.error('Error fetching profile:', error);
                setErrorMessage('Error fetching profile');
            }
        };
        fetchProfile();
    }, []);

    const handleChange = (e) => {
        setNewProfile({ ...newProfile, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('/api/postProfile', newProfile);
            if (response.data.success) {
                setSuccessMessage('Profile updated successfully!');
            } else {
                setErrorMessage('Failed to update profile.');
            }
        } catch (error) {
            console.error('Error updating profile:', error);
            setErrorMessage('Error updating profile.');
        }
    };

    const handleImageChange = (e) => {
        const selectedImage = e.target.files[0];
        setImage(selectedImage);

        if (selectedImage) {
            const formData = new FormData();
            formData.append('image', selectedImage);

            // Automatically send image upload request as soon as the file is selected
            const uploadImage = async () => {
                try {
                    const response = await axios.post('/api/profile/image', formData, {
                        headers: { 'Content-Type': 'multipart/form-data' }
                    });
                    if (response.data.success) {
                        setSuccessMessage('Profile image updated successfully.');
                    } else {
                        setErrorMessage('Failed to update profile image.');
                    }
                } catch (error) {
                    console.error('Error uploading profile image:', error);
                    setErrorMessage('Error uploading profile image.');
                }
            };

            uploadImage();  // Call the function to upload the image immediately
        }
    };


    return (
        <ProfileContainer>
            <h2>Modify Profile</h2>
            <form onSubmit={handleSubmit}>
                <label>
                    Profile Name:
                    <input type="text" name="profileName" value={newProfile.profileName} onChange={handleChange} />
                </label>
                <label>
                    Profile Interests:
                    <input type="text" name="profileInterest" value={newProfile.profileInterest} onChange={handleChange} />
                </label>
                <button type="submit">Update Profile</button>
            </form>

            {successMessage && <p>{successMessage}</p>}
            {errorMessage && <p>{errorMessage}</p>}

            <h3>Change Profile Image</h3>
            <input type="file" onChange={handleImageChange} />
        </ProfileContainer>
    );
};

const ProfileContainer = styled.div`
    width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid green;
    border-radius: 8px;
    background-color: #f4f4f4;
    color: black;

    label {
        display: block;
        margin-bottom: 10px;
    }

    input {
        width: 100%;
        padding: 8px;
        margin-bottom: 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
    }

    button {
        padding: 10px 20px;
        background-color: green;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
    }

    button:hover {
        background-color: darkgreen;
    }
`;

export default ModifyProfile;
