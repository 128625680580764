import React, { useState } from 'react';
import styled from 'styled-components';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const FormLogin = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [userHasAccessToken, setUserHasAccessToken] = useState(false);

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const generateAccessTokenA = async () => {
    try {
        // Generate and store a new access token
        const response = await axios.post('/api/getAccessTokenA', { email: email });
        const { accessToken } = response.data;
        Cookies.set('accessTokenA', accessToken);
        setUserHasAccessToken(true);
    } catch (error) {
        console.error(error);
    }
};

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            // Send a POST request to your login endpoint
            const response = await axios.post('/api/login', { email, password });

            // If login is successful, you should receive an access token in the response
            const { token } = response.data;

            // Generate and store a new access token
            Cookies.set('accessTokenA', token);

            // Redirect the user to the authenticated part of your application
            // Replace '/app' with the appropriate route in your application
            window.location.href = '/app';
        } catch (error) {
            // Handle login errors and show a user-friendly message
            setError('Invalid email or password. Please try again.');
        }
    };

    return (
        <FormContainer onSubmit={handleSubmit}>
            <div>
                <h2>Log In</h2>
                <label htmlFor="email">Email:</label>
                <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={handleEmailChange}
                    style={{backgroundColor:'black', border: '1px solid green', color: 'green'}}
                />
            </div>
            <div>
                <label htmlFor="password">Password:</label>
                <input
                    type="password"
                    id="password"
                    value={password}
                    onChange={handlePasswordChange}
                    style={{backgroundColor:'black', border: '1px solid green', color: 'green'}}
                />
            </div>
            <button type="submit" className="button-pink">
                Access
            </button>
            {error && <p className="error">{error}</p>}
        </FormContainer>
    );
};

const FormContainer = styled.form`
    border: 1px solid green;
    padding: 10px;
    width: 400px;
    text-align: center;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    margin-top: 7vh;
    margin-left: 7vh;
    display: flex;
    flex-direction: column;
    align-items: end;
    color: green;
`;

export default FormLogin;
