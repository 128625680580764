import React from 'react';
import ReactPlayer from 'react-player';

const MusicBox = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div
        style={{
          borderRadius: '10px',
          marginBottom: '1vh',
          overflow: 'hidden', // Ensure the background color is visible
        }}
      >
        <ReactPlayer
          url="/images/Demo/WhiteLines.mp3"
          playing={false}
          controls={true}
          width="384px"
          height="7vh"
          style={{
            backgroundColor: 'black', // Set the background color to black
            color: 'white', // Set the text color to white
            borderRadius: '33px',
            overflow: 'hidden',
          }}
        />
      </div>
    </div>
  );
};

export default MusicBox;
