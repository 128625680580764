function Founder() {
    return (   
<div className="founder" id="founder">
<img className="founder-bg" src={process.env.PUBLIC_URL + "/images/about/AboutUsBackground.png"} alt="" />
<img className="founder-splash" src={process.env.PUBLIC_URL + "/images/about/AboutUsPaintSplatter.png"} alt="" />
<div className="founder-content"><div className="about-story"> <div className="green">OUR&nbsp;</div> FOUNDER</div>
<div className="founder-text">
        A man from very humble beginnings. Before the scamdemic, he had beaten the odds to become a successful artist, designer and self-made businessman, supporting the community at large. But they say crisis precipitate change. And change was needed. 
        <br></br> Now living life as a sovereign citizen of the world. Making the choice to no longer use banks. Leading him down the rabbit hole of Crypto. Sparking his creativity on how to use this new technology in a way that will not make the powers at play (Blackrock, Vanguard, Government agencies) very happy. The result: Project Defi.
</div> 
<img className="founder-image"src={process.env.PUBLIC_URL + "/images/founder/TheFounderProfileImage.png"} alt="" />
</div>
</div>

    )
}    
export default Founder;