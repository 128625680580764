import React from 'react';
import styled from 'styled-components';
import ContentItem from './ContentItem';

const ContentContainer2 = () => {
    return (
        <StyledContentContainer>
            <ContentItem image={process.env.PUBLIC_URL + "/images/Demo/car2.png"} style={{ marginLeft: '-17.5%' }} />
            <ContentItem image={process.env.PUBLIC_URL + "/images/Demo/tattoo2.jpg"} />
            <ContentItem image={process.env.PUBLIC_URL + "/images/Demo/btc2.jpg"} />
            <ContentItem image={process.env.PUBLIC_URL + "/images/Demo/diy2.jpg"} style={{ marginRight: '-17.5%' }} />
        </StyledContentContainer>
    );
};

export default ContentContainer2;

const StyledContentContainer = styled.div`
    width: 100%;
    height: 15vh;
    background-color: black;
    display: flex;
    overflow: hidden;
    justify-content: space-between;
`;
