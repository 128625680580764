function About() {
    return (
        <div className="about" id="about">
            <img className="about-bg" src={process.env.PUBLIC_URL + "/images/about/AboutUsBackground.png"} alt="" />
            <img className="about-splash" src={process.env.PUBLIC_URL + "/images/about/AboutUsPaintSplatter.png"} alt="" />
            <div className="about-content"><div className="about-story"> <div className="green about-our">OUR</div> STORY</div>
                <div className="about-text">
                    We are the disrupters of the status quo, the artist, the very people who question the power in play. The early adopters of change and opportunity.
                    <br></br>
                    The new wave of community builders.
                    <br></br>
                    Striving for financial equality for all humanity.
                    <br></br>
                    The ultimate goal being equal access for all, to a financial system that is incorruptible.
                    <br></br>
                    Utilising cutting edge technology that will even the playing field for every person on Earth, regardless of location, race and especially economic status.
                    <br></br> 
                    <br></br> 
                    Change is coming.
                </div> 
                <img className="about-brad-pitt"src={process.env.PUBLIC_URL + "/images/about/AboutUsBradPittImage.png"} alt="" />
            </div>
        </div>
    )
}    
 
export default About;