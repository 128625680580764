import React from 'react';
import styled from 'styled-components';

const ProgressLog = ({ log }) => {
  return (
    <LogContainer>
      {log.map((entry, index) => (
        <LogLine key={index}>{entry}</LogLine>
      ))}
    </LogContainer>
  );
};

const LogContainer = styled.div`
  border: 1px solid green;
  padding: 10px;
  width: 400px;
  text-align: center;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  margin-top: 7vh;
  margin-left: 7vh;
`;

const LogLine = styled.div`
  color: green;
  margin-bottom: 5px;
`;

export default ProgressLog;
