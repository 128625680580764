import React from 'react';
import styled from 'styled-components';

const Footer = () => {
  return (
    <ProfileFooter>
    <AddIcon src={process.env.PUBLIC_URL + "/images/app/addIcon.png"} />
    <KArrowUp src={process.env.PUBLIC_URL + "/images/app/kArrowUpFullLine.png"}/>
    <BellIcon src={process.env.PUBLIC_URL + "/images/app/bellIcon.png"} />
    </ProfileFooter>
  );
};

export default Footer;

const ProfileFooter = styled.div`
  display: flex;
  justify-content: space-between; /* Distribute the items equally */
  width: 100%;
  height: 7vh;
  background-color: black;
  align-items: center;
`;

const AddIcon = styled.img`
  height: 70%;
  margin-left: 3vw;
  margin-top: 2vh;
`;

const BellIcon = styled.img`
  height: 70%;
  margin-right: 3vw;
  margin-top: 2vh;
`;

const KArrowUp = styled.img`
    position: absolute;
    left: 50%;
    top: 92vh;
    transform: translateX(-50%);
    width: 100vw;
`;