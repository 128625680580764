import React, { useState } from 'react';
import styled from 'styled-components';
import Interest from './Interest';

const InterestContainer = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const rotateLeft = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + 4) % 4); // 4 is the total number of interests
  };

  const rotateRight = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % 4);
  };

  return (
    <StyledInterestContainer > {/*currentIndex={currentIndex}*/}
      {interests.map((interest, index) => (
        <Interest
          key={index}
          title={interest.title}
          image={interest.image}
          style={{ marginLeft: index === 0 ? '-17.5%' : undefined, marginRight: index === 3 ? '-17.5%' : undefined }}
        />
      ))}
      <KArrowDown src={process.env.PUBLIC_URL + "/images/app/kArrowDown.png"} />
      <KArrowDownLeft src={process.env.PUBLIC_URL + "/images/app/InterestArrowLeft.png"}  /> {/*onClick={rotateRight} */}
      <KArrowDownRight src={process.env.PUBLIC_URL + "/images/app/InterestArrowLeft.png"} />{/*onClick={rotateLeft} */}
    </StyledInterestContainer>
  );
};

const interests = [
  { title: "Cars", image: process.env.PUBLIC_URL + "/images/Demo/car.jpg" },
  { title: "Tattoos", image: process.env.PUBLIC_URL + "/images/Demo/tattoo.jpg" },
  { title: "Crypto", image: process.env.PUBLIC_URL + "/images/Demo/btc.jpg" },
  { title: "DIY", image: process.env.PUBLIC_URL + "/images/Demo/diy.jpg" },
];

export default InterestContainer;

const StyledInterestContainer = styled.div`
  position: relative;
  width: 100%;
  height: 28vh;
  background-color: black;
  display: flex;
  overflow: hidden;
  justify-content: space-between;
  transition: transform 0.5s ease; /* Add a transition for smooth translation */
  transform: ${({ currentIndex }) => `translateX(${-currentIndex * 25}%)`};
`;

const KArrowDown = styled.img`
  position: absolute;
  bottom: 0;
  width: 100vw;
  height: 5vh;
`;

const KArrowDownLeft = styled.img`
  margin-left: 1vw;
  position: absolute;
  height: 9.8vh;
  top: 6.2vh;
  left: 0;
  transform: none;
  cursor: pointer;
`;

const KArrowDownRight = styled.img`
  margin-right: 1vw;
  position: absolute;
  height: 9.8vh;
  top: 6.2vh;
  right: 0;
  transform: rotate(180deg);
  cursor: pointer;
`;

//A implementer:
//https://codepen.io/team/keyframers/pen/rNxmVZN