import React from 'react';
import styled from 'styled-components';

const Modal = ({ isLoading }) => {
    if (!isLoading) {
        return null;
    }

    return (
        <Backdrop>
        <Message>
            We are verifing the ownership of your NFT through the blockchain.<br/>
            This may take some time.
        </Message>
        </Backdrop>
    );
};

const Backdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Message = styled.p`
    background-color: white;
    padding: 20px;
    border-radius: 5px;
`;

export default Modal;
