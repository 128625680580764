import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Modal from './Modal'; 
import Web3 from "web3";
let web3 = new Web3("https://rpc.ankr.com/eth");
const ADDRESS = "0x5460687A450450355722C489877CF6C2ef54374C";
const ABI = require("./ABI.js");

const SignupForm = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [profileName, setProfileName] = useState('');  // New state for profileName
    const [passwordMatchError, setPasswordMatchError] = useState(false);
    const [emailFormatError, setEmailFormatError] = useState(false);
    const [profileNameError, setProfileNameError] = useState(false); // New state for profileName validation
    const [userHasAccessToken, setUserHasAccessToken] = useState(false);
    const [nftTokenIds, setNftTokenIds] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
    }, [nftTokenIds]);
    const navigate = useNavigate();

    useEffect(() => {
    if (userHasAccessToken) {
        navigate('/app');
        }
    }, [userHasAccessToken, navigate]);

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        setEmailFormatError(false);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        setPasswordMatchError(false);
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
        setPasswordMatchError(false);
    };

    const handleProfileNameChange = (e) => {  // New handler for profileName
        setProfileName(e.target.value);
        setProfileNameError(false);
    };

    // Function to fetch and set the NFT token IDs
    const listOwnedNftTokenIds = async () => {
        try {
            // Request access to the user's Metamask wallet
            await window.ethereum.request({ method: 'eth_requestAccounts' });

            // Get the selected Ethereum address from the wallet
            const [walletAddress] = await window.ethereum.request({ method: 'eth_accounts' });

            // Fetch all NFT token IDs from the smart contract using Web3.js
            const contract = new web3.eth.Contract(ABI, ADDRESS);
            const totalTokens = await contract.methods.totalSupply().call();

            const ownedTokenIds = [];

            for (let i = 0; i < totalTokens; i++) {
                try {
                    const tokenId = await contract.methods.ownerOf(i).call();
                    if (tokenId.toLowerCase() === walletAddress.toLowerCase()) {
                        ownedTokenIds.push(i);
                    }
                } catch (error) {
                    // Ignore the error if 'ownerOf' throws
                }
            }

            return ownedTokenIds;
        } catch (error) {
            console.error(error);
            return [];
        }
    };

    const generateAccessTokenA = async () => {
        try {
            // Generate and store a new access token
            const response = await axios.post('/api/getAccessTokenA', { email: email });
            const { accessToken } = response.data;
            Cookies.set('accessTokenA', accessToken);
            setUserHasAccessToken(true);
        } catch (error) {
            console.error(error);
        }
    };

    const checkTokenValidity = async () => {
        const accessTokenC = Cookies.get('accessTokenC');
        if (!accessTokenC) {
            return false; 
        }

        try {
            const response = await axios.post('/api/verifyAccessTokenC', { accessTokenC });
            return response.data.valid;
        } catch (error) {
            console.log('Token verification failed:', error);
            return false;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Profile name validation
        const profileNameRegex = /^[a-zA-Z0-9]{3,20}$/; // Alphanumeric, 3-20 characters
        if (!profileName.match(profileNameRegex)) {
            setProfileNameError(true);
            return;
        }

        // Password matching validation
        if (password !== confirmPassword) {
            setPasswordMatchError(true);
            return;
        }

        // Email format validation using regex
        const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (!email.match(emailRegex)) {
            setEmailFormatError(true);
            return;
        }

        // Show loading screen popup
        setIsLoading(true);

        // Fetch NFT token IDs
        const fetchedNftTokenIds = await listOwnedNftTokenIds();

        // Hide loading screen popup
        setIsLoading(false);

        // Check token validity
        const validToken = await checkTokenValidity();
        if (validToken) {
            try {
                // Send signup request with email, password, profileName, and NFT token IDs
                const response = await axios.post('/api/signup', {
                    email,
                    password,
                    profileName,  // Include profileName in the signup request
                    nftTokenIds: fetchedNftTokenIds,
                });

                if (response.data.success) {
                    setEmail('');
                    setPassword('');
                    setConfirmPassword('');
                    setProfileName('');  // Clear profileName

                    // Generate and store a new access token
                    generateAccessTokenA();
                } else {
                    console.log('Signup failed:', response.data.error);
                }
            } catch (error) {
                console.log('Signup failed:', error);
            }
        } else {
            console.log('Token is not valid. Signup not allowed.');
        }
    };

    return (
        <FormContainer onSubmit={handleSubmit}>
            <div>
                <h2>Register</h2>
                <label htmlFor="email">Email:</label>
                <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={handleEmailChange}
                    style={{backgroundColor:'black', border: '1px solid green', color: 'green'}}
                />
                {emailFormatError && <p className="error">Invalid email format</p>}
            </div>
            <div>
                <label htmlFor="profileName">Profile Name:</label>  {/* New input field for profileName */}
                <input
                    type="text"
                    id="profileName"
                    value={profileName}
                    onChange={handleProfileNameChange}
                    style={{backgroundColor:'black', border: '1px solid green', color: 'green'}}
                />
                {profileNameError && <p className="error">Profile name must be 3-20 characters and alphanumeric</p>}
            </div>
            <div>
                <label htmlFor="password">Password:</label>
                <input
                    type="password"
                    id="password"
                    value={password}
                    onChange={handlePasswordChange}
                    style={{backgroundColor:'black', border: '1px solid green', color: 'green'}}
                />
            </div>
            <div>
                <label htmlFor="confirmPassword">Confirm Password:</label>
                <input
                    type="password"
                    id="confirmPassword"
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                    style={{backgroundColor:'black', border: '1px solid green', color: 'green'}}
                />
                {passwordMatchError && <p className="error">Passwords do not match</p>}
            </div>
            <button style={{marginTop:'10px'}} type="submit" className="button-pink">
                Sign Up
            </button>
            <Modal isLoading={isLoading} />
        </FormContainer>
    );
};

const FormContainer = styled.form`
    border: 1px solid green;
    padding: 10px;
    width: 400px;
    text-align: center;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    margin-top: 7vh;
    margin-left: 7vh;
    display: flex;
    flex-direction: column;
    align-items: end;
    color: green;
`;

export default SignupForm;
