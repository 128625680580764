import React from 'react';
import styled from 'styled-components';

const ContentItem = (props) => {
    return (
        <ContentCard>
            <InterestContent>
                <ContentImage src={props.image} alt={props.title} />
            </InterestContent>
        </ContentCard>
    );
};

const ContentCard = styled.div`
    flex: 1;
    position: relative;
    margin-left: 1vw;
    margin-right:1vw;
`;

const InterestContent = styled.div`
    height: 15vh;
    background-color: black;
`;

const ContentImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: 1px solid gray;
    border-radius: 10px;
`;

export default ContentItem;
