import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import JokerAudioPage from './JokerAudioComponent';
import ReactHtmlParser from 'react-html-parser';
import DOMPurify from 'dompurify';
import axios from 'axios';

export default function Tos() {
  const navigate = useNavigate();
  const textToType = `--By being lucky enough to create an account, you are agreeing to follow the rules of the platform.<br></br>
We agree to provide you with true SOCIAL NETWORKING. Please do not mistake this with the evil monster that is social media 👎<br></br>

Offering a meaningful way to truly kanect with people that have common interest. We offer different types of accounts. To help grow and strengthen 💪our kanections with people and our real interest. We have built a way to share your favorite content with each other organically 🥦. There is no stupid algorithm on this platform because we do not believe in whoring people out 💲. You are not the product. Since we will never allow on chain advertising, we don’t have to keep any of Karen's in the world 🌎happy. We highly encourage affiliate marketing because we believe in freedom of choice. If you don't like a persons content that you have kanected with then simply unfollow the person 🚫. Please do not try to report someone because they hurt your fragile feelings.<br></br>

In addition to offering you the real SOCIAL NETWORKING experience we will offer a defi marketplace. All transactions will be done with CRYPTO CURRENCY’S. For now, we will be allowing limited CRYPTO CURRENCY’S as a form of payment. The list will be as follows.<br></br>

1. The OG king of crypto BITCOIN<br></br>
2. A true innovator and the entrepreneur’s choice ETHEREUM<br></br>
3. Last but definitely not least our personal favorite XMP ( MONERO ) anything that the IRS ( International Revenue of Schmucks ) will put a 625,000 bounty to have cracked, we LOVE ❤<br></br>

There will be the ability to monetize yourself on the platform, in many ways. Anyone that chooses to monetize themselves will have full rights/ownership to the content they create.<br></br>

We believe in true freedom of choice and true freedom of speech. You can say FUCK, SHIT , CUNT ( which for all you Aussie cunts is a beautiful word and we love how versatile this word is) DILDO BAGGINS etc. Say them as much as you want without risking demonetization or being deplatformed!<br></br>

What will get you de platformed?<br></br>

Any form of child nudity or explicit content of children is prohibited, and we hope someone finds you and cuts of some of you limbs 🍆. Children are the future and are to be held to the highest form of respect. In addition, this really isn’t exactly a platform most children should be on in general.<br></br>

Animal abuse. So no Micheal Vick type of shit. Pretty simple concept if you ask us.<br></br>

Bots are not allowed on the platform. We are not trying to inflate the number of users we don't have to keep GREEDY PIG 🐷 investors/ advertisers pockets full.<br></br>

Stealing someone’s content!! Thieves are right up there with the scum of the earth and should also have some of their limbs cut off 🔪🤚 🩸<br></br>



Disputes will be handled by the Project Defi NFT holders.<br></br>

We will also provide buyers and sellers protection. For marketplace purchase’s.<br></br>

We will never sell any of your data to 3rd party organizations or users.<br></br>

If you don't like these terms of use then FUCK OFF and go use FACECRAP 💩, INSTATRASH 🗑 , or TWIDDLE 🐦. 😘<br></br>`.trim();
  const typingSpeed = 1;
  const [charIndex, setCharIndex] = useState(0);
  const [message, setMessage] = useState('');
  const [showAdditionalSection, setShowAdditionalSection] = useState(false);
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [showJokerAudio, setShowJokerAudio] = useState(false);
  const [textVisible, setTextVisible] = useState(true);
  const [continueButtonVisible, setContinueButtonVisible] = useState(true);
  const [buttonClicked, setButtonClicked] = useState(false);
  const audioRef = useRef();

  useEffect(() => {
    const checkAccessToken = async () => {
        try {
            // Retrieve the accessTokenA from the cookie
            const accessTokenA = Cookies.get('accessTokenA');
            if (!accessTokenA){
              navigate('/auth');
            }
            else if (accessTokenA) {
                // Send the accessTokenA to the backend for validation
                const response = await axios.post('/api/verifyAccessTokenA', { accessTokenA });

                if (response.data.valid) {
                // Token is valid, check status of the account
                try {
                const accessTokenA = Cookies.get('accessTokenA');
                // Make a request to the server to check the user's status
                const response = await fetch('/api/getAccountDetails', {
                    method: 'POST',
                    // Include any necessary request data (e.g., userId) in the request body
                    body: JSON.stringify({ accessTokenA }),
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (response.ok) {
                    // User has access to the normal content, continue rendering the page
                    navigate('/app');
                } else if (response.status === 403) {
                    // Handle different 403 error scenarios
                    const errorData = await response.json();

                    if (errorData.reason === 'terms-not-signed') {
                        // User has not signed the terms of services, redirect to the terms page
                        navigate('/tos');
                    } else if (errorData.reason === 'account-details-incomplete') {
                        // User's account details are incomplete, redirect to the account details page
                        navigate('/account-details');
                    } else {
                        // Unknown 403 error scenario, redirect to the error page
                        navigate('/auth');
                    }
                } else {
                    // Handle other error cases (e.g., server error)
                    // Redirect to an error page or display an error message
                    navigate('/auth');
                }
            } catch (error) {
                console.error('Error checking user status:', error);
                // Handle error cases (e.g., network error)
                // Redirect to an error page or display an error message
                navigate('/auth');
            }
                }
            } 
        } catch (error) {
            console.log('Token validation failed:', error);
        }
    };
        // Call the function to check the accessTokenA on component mount
        checkAccessToken();
    }, [navigate]);

  const handleCheckboxChange = () => {
    if (!showJokerAudio) {
      setCheckboxChecked(!checkboxChecked);
      setShowJokerAudio(true);
      setTextVisible(false);
      setContinueButtonVisible(false);

      setTimeout(() => {
        setCharIndex(0);
        setShowJokerAudio(false);
        setMessage('');
        setCheckboxChecked(true);
        setShowAdditionalSection(true);
        setTextVisible(true);
      }, 11000); // 11 seconds
    }
  };

  useEffect(() => {
    if (!audioRef.current) {
      audioRef.current = new Audio('/media/keyboardSound.mp3');
      audioRef.current.loop = true; // Set audio to loop
    }

    const audioLoop = () => {
      audioRef.current.play().catch((error) => {
        console.error('Error playing audio:', error);
      });
    };

    audioRef.current.addEventListener('ended', audioLoop);

    if (buttonClicked) {
      audioLoop(); // Play audio when the component mounts if the button is already clicked
    }

    return () => {
      audioRef.current.pause();
      audioRef.current.removeEventListener('ended', audioLoop);
    };
  }, [buttonClicked]);

  useEffect(() => {
    if (buttonClicked) {
      let charIndex = 0; // Start with 0
      const textLength = textToType.length;

      const typeCharacters = () => {
        if (charIndex < textLength) {
          setMessage((prevMessage) => prevMessage + textToType.charAt(charIndex));
          charIndex++;
        } else {
          setShowAdditionalSection(true);
          if (!showJokerAudio) {
            setContinueButtonVisible(true);
            audioRef.current.pause(); // Pause the audio when typewriter effect is finished
          }
        }
      };

      const typingInterval = setInterval(typeCharacters, typingSpeed);

      return () => {
        clearInterval(typingInterval);
      };
    }
  }, [textToType, showJokerAudio, buttonClicked]);

  const sanitizedHTML = DOMPurify.sanitize(message);
  const parsedHTML = ReactHtmlParser(sanitizedHTML);

  const handleAcceptTerms = async () => {
    try {
      const accessTokenA = Cookies.get('accessTokenA');
      const response = await fetch('/api/updateTermsSigned', {
        method: 'POST',
        body: JSON.stringify({ accessTokenA }),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        navigate('/account-details');
      } else {
        console.error('Error updating termsSigned:', response.status);
      }
    } catch (error) {
      console.error('Error updating termsSigned:', error);
    }
  };

  return (
    <>
      <div style={{ height: '100vh', overflowY: 'auto' }}>
        <Background/>
        <div
          style={{
            color: 'green',
            textAlign: 'justify',
            height: '100vh',
            marginTop: '7vh',
            marginLeft: '7vw',
            marginRight: '7vw',
          }}
        >
          {!showJokerAudio && <h1 style={{ color: 'rgb(241, 4, 176)' }}>Welcome to project defi</h1>} <br></br>
          <br></br>
          <div>
            {showJokerAudio && <JokerAudioPage />}
            {!showJokerAudio && (
              <div>
                <h3>Project defi terms of use:</h3>
                <div style={{display:'flex'}}>
                  <h5 style={{ marginTop: '15px' }}>The terms of service are very IMPORTANT to us, as they should be for you. PLEASE read them carefully</h5>
                  {buttonClicked ? null : (
                    <button style={{marginLeft:'15px'}} className='button-pink' onClick={() => setButtonClicked(true)}>Start</button>
                  )}
                </div>
                
                <div style={{ display: textVisible ? 'block' : 'none' }}>{parsedHTML}</div>
              </div>
            )}
          </div>

          {showAdditionalSection && continueButtonVisible && (
            <div style={{ marginTop: '25px', fontSize: '1.5em' }}>
              {!checkboxChecked && (
                <>
                  <input type="checkbox" onChange={handleCheckboxChange} checked={checkboxChecked} /> I agree that I have not read the terms of service <br></br>
                </>
              )}
              <button style={{ marginTop: '7vh' }} className='button-pink' onClick={() => handleAcceptTerms()}>Continue</button>
            </div>
          )}
          <br></br>
          <br></br>
        </div>
      </div>
    </>
  );
}

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* Place it behind other elements */
  background-image: url("/images/hero/HeroPageBacground.png");
  background-size: cover; /* Ensures the image covers the entire screen */
  background-repeat: no-repeat; /* Prevents repeating the image if it's smaller than the screen */
`;